import {
  StrapiArrayResponse,
  StrapiDocument,
  StrapiObjectResponse,
} from 'core/strapi'

// fetching
export function getStrapiURL(path = '') {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://127.0.0.1:1337'
  }${path}`
}

export function getStrapiMedia(media: any) {
  const imageUrl = media?.url?.startsWith('/')
    ? getStrapiURL(media.url)
    : media?.url
  return imageUrl
}

export const parseRecursive = <T extends Record<string, any>>(model: T) => {
  return Object.entries(model)
    .map(([key, value]) => {
      if (value && typeof value === 'object' && value?.hasOwnProperty('data')) {
        if (value.data === null) {
          // Handle the null value case
          return [key, null]
        }
        if (Array.isArray(value.data)) {
          return [
            key,
            value.data.length ? parseStrapiArray<any>(value) : [],
          ] as [string, any]
        }
        return [key, parseStrapiObject<any>(value)]
      }
      return [key, value]
    })
    .reduce((accum: any, [k, v]) => {
      accum[k] = v
      return accum
    }, {})
}

// parsing
export const parseDocument = <T>(document: StrapiDocument<T>): T => {
  return {
    id: document.id,
    ...document.attributes,
  }
}

export const parseStrapiArray = <T extends Record<string, any>>(
  response: StrapiArrayResponse<T>
): T[] => {
  const result = response.data
    .map((document) => parseDocument<T>(document))
    .map((model) => parseRecursive<T>(model))
  return result
}

export const parseStrapiObject = <T extends Record<string, any>>(
  response: StrapiObjectResponse<T>
): T => {
  const model = parseDocument<T>(response.data)
  return parseRecursive(model)
}
