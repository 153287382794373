import React, { FC } from "react"

type Props = {
  children: React.ReactNode,
}

export const FullHeightLayout: FC<Props> = ({ children }) => {
  return (
    <div className="absolute bottom-0 w-full overflow-auto top-16">
      {children}
    </div>
  )
}