import React, { FC } from "react"

import { Header } from "components/shared/Header"
import { Footer } from "components/shared/Footer"
import { FullHeightLayout } from "components/shared/FullHeightLayout"

type Props = {
  children: React.ReactNode,
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <FullHeightLayout>
        {children}
        <Footer />
      </FullHeightLayout>
    </>
  )
}

export default Layout
