import axios from "axios"
import { getStrapiURL } from "./strapi"

const api = axios.create({
  baseURL: `${getStrapiURL()}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
})

export default api