import { Newsletter } from "core/entities"
import { StrapiObjectResponse } from "core/strapi"

import api from "./api"
import { parseStrapiObject } from "./strapi"

export const addNewsletter = async (email: string) => {
  const response = await api.post<StrapiObjectResponse<Newsletter>>("/newsletters", { data: { email }})
  const result = parseStrapiObject<Newsletter>(response.data)
  return result
}

export const NewsletterApiService = {
  addNewsletter
}